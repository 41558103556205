import React from "react";

const alignText = (alignment, props) => (
    <span style={{ textAlign: alignment, display: 'block' }}>{props.children}</span>
);

export const center = props => {
    return alignText("center", props);
};

export const left = props => {
    return alignText("left", props);
};


export const right = props => {
    return alignText("right", props);
};
